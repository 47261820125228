import React from 'react';
import styled from 'styled-components';

import { theme } from '../../config/Theme';

const TableHeaderRow = ({ title, icons }) => {
	return (
		<Wrapper>
			<Title>{title}</Title>
			{icons.map((icon, i) => (
				<IconContainer key={i}>
					<HostIcon src={icon} />
				</IconContainer>
			))}
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1.5fr repeat(6, 1fr);
	width: 100%;
`;

const Title = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500;
	color: ${theme.colors.primary};
	font-size: 1.125rem;
	height: 100%;
`;

const IconContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 0.5rem 0;
`;

const HostIcon = styled.img`
	width: 3rem;
	height: 3rem;
`;
export default TableHeaderRow;
