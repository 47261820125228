import React from 'react';
import styled from 'styled-components';
import Logo from './Logo';
import NavLink from './NavLink';

import { navbarConfig } from '../../config/Placeholders';

const Navbar = () => {
	return (
		<Wrapper>
			<NavContainer>
				<Logo />
				<LinksContainer>
					{navbarConfig.navlinks.map((item, i) => (
						<NavLink title={item.name} link={item.link} key={i} />
					))}
				</LinksContainer>
				<NavSpacer />
			</NavContainer>
		</Wrapper>
	);
};

const Wrapper = styled.nav`
	height: 5rem;
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 0rem 2rem;
	background-color: ${({ theme }) => theme.colors.background};
	color: ${({ theme }) => theme.colors.primary};
	box-sizing: border-box;
	border-bottom: 4px solid ${({ theme }) => theme.colors.navBorder};
`;

const NavContainer = styled.div`
	height: 100%;
	width: 100%;
	max-width: 1140px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

const NavSpacer = styled.div`
	height: 100%;
	width: 100%;
	max-width: 240px;
`;

const LinksContainer = styled.div`
	display: flex;
	flex-direction: row;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
	gap: 2rem;
	padding: 0 1rem;
`;

export default Navbar;
