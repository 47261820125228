import React from 'react';
import styled from 'styled-components';

import { theme } from '../../config/Theme';
import { footerConfig } from '../../config/Placeholders';

const AboutSection = () => {
	return (
		<Wrapper>
			<Header>{footerConfig.aboutUs.title}</Header>
			<span>{footerConfig.aboutUs.text}</span>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	width: 100%;

	& > span {
		font-size: 0.875rem;
		color: ${theme.colors.secondary};
	}
`;

const Header = styled.div`
	display: flex;
	align-items: flex-end;
	width: 100%;
	color: ${theme.colors.primary};
	font-size: 1.25rem;
	font-weight: 500;
	gap: 0.75rem;

	& > img {
		max-height: 48px;
	}
`;

export default AboutSection;
