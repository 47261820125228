import React from 'react';
import styled from 'styled-components';

import { FaCheck, FaTimesCircle } from 'react-icons/fa';

import { theme } from '../../config/Theme';

const TableIconRow = ({ title, host1, host2, host3, host4, host5, host6 }) => {
	return (
		<Wrapper>
			<Title>{title}</Title>
			<IconContainer>
				{host1 ? (
					<FaCheck size={'24px'} color={theme.colors.iconGreen} />
				) : (
					<FaTimesCircle size={'24px'} color={theme.colors.iconRed} />
				)}
			</IconContainer>
			<IconContainer>
				{host2 ? (
					<FaCheck size={'24px'} color={theme.colors.iconGreen} />
				) : (
					<FaTimesCircle size={'24px'} color={theme.colors.iconRed} />
				)}
			</IconContainer>
			<IconContainer>
				{host3 ? (
					<FaCheck size={'24px'} color={theme.colors.iconGreen} />
				) : (
					<FaTimesCircle size={'24px'} color={theme.colors.iconRed} />
				)}
			</IconContainer>
			<IconContainer>
				{host4 ? (
					<FaCheck size={'24px'} color={theme.colors.iconGreen} />
				) : (
					<FaTimesCircle size={'24px'} color={theme.colors.iconRed} />
				)}
			</IconContainer>
			<IconContainer>
				{host5 ? (
					<FaCheck size={'24px'} color={theme.colors.iconGreen} />
				) : (
					<FaTimesCircle size={'24px'} color={theme.colors.iconRed} />
				)}
			</IconContainer>
			<IconContainer>
				{host6 ? (
					<FaCheck size={'24px'} color={theme.colors.iconGreen} />
				) : (
					<FaTimesCircle size={'24px'} color={theme.colors.iconRed} />
				)}
			</IconContainer>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1.5fr repeat(6, 1fr);
	width: 100%;
	padding: 1.5rem 0;
`;

const Title = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${theme.colors.primary};
	font-size: 1.125rem;
	height: 100%;
	text-align: center;
`;

const IconContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`;

export default TableIconRow;
