import React from 'react';
import styled from 'styled-components';

import { theme } from '../../config/Theme';

const FooterColumn = ({ title, rows }) => {
	return (
		<Wrapper>
			<Header>{title}</Header>
			<ElementContainer>
				{rows.map((row, i) => (
					<div key={i}>
						{row.icon}{' '}
						<a href={row.link} target={'_blank'} rel={'noreferrer'}>
							{row.text}
						</a>
					</div>
				))}
			</ElementContainer>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	margin: 0 auto;
`;

const Header = styled.div`
	color: ${theme.colors.primary};
	font-size: 1.25rem;
	font-weight: 500;
`;

const ElementContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 0.5rem;
	color: ${theme.colors.secondary};
	font-size: 0.875rem;

	& > div {
		display: flex;
		align-items: center;
		gap: 0.25rem;
	}

	a,
	a:hover,
	a:focus,
	a:active {
		color: inherit;
		text-decoration: none;
	}
`;

export default FooterColumn;
