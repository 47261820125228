import React, { useState } from 'react';
import styled from 'styled-components';
import CircularSlider from '@fseehawer/react-circular-slider';

import { FaCheck, FaTimesCircle } from 'react-icons/fa';

import UsageElement from './UsageElement';

const defaultReccUsage = {
	vanillaMC: true,
	lightModpack: false,
	heavyModpack: false,
	vanillaPlayerCount: '12 Players',
	lightPlayerCount: 'Not Recommended',
	heavyPlayerCount: 'Not Recommended',
	vanillaPluginCount: '4 Plugins',
	lightModCount: 'Not Recommended',
	heavyModCount: 'Not Recommended'
};

const RamSelector = ({ maxRam, ramValState }) => {
	const [reccUsage, setReccUsage] = useState(defaultReccUsage);

	const Xicon = <FaTimesCircle size={'24px'} color={'#AD5050'} />;
	const CheckIcon = <FaCheck size={'24px'} color={'#50AD69'} />;

	const checkReccUsage = (value) => {
		if (value > 6) {
			setReccUsage((prevState) => ({
				...prevState,
				vanillaPlayerCount: '124 Players',
				vanillaPluginCount: '24 Plugins',
				lightModCount: '12 Mods',
				lightPlayerCount: '24 Players',
				heavyModpack: true,
				heavyModCount: 24,
				heavyPlayerCount: '12 Players'
			}));
		} else if (value > 4) {
			setReccUsage((prevState) => ({
				...prevState,
				vanillaPlayerCount: '64 Players',
				vanillaPluginCount: '12 Plugins',
				lightModpack: true,
				lightModCount: '4 Mods',
				lightPlayerCount: '8 Players'
			}));
		} else {
			setReccUsage(defaultReccUsage);
		}
	};

	return (
		<Wrapper>
			<Container>
				<ContentContainer>
					<CircularSlider
						width={160}
						label='GB'
						labelBottom
						labelColor='#D6E4EF'
						knobColor='#366CAA'
						progressColorFrom='#6C819C'
						progressColorTo='#366CAA'
						progressSize={16}
						trackColor='#D6E4EF'
						trackSize={12}
						min={1}
						max={maxRam}
						onChange={(value) => {
							ramValState(value);
							checkReccUsage(value);
						}}
					/>
					<ReccUsageContainer>
						<UsageElement
							icon={reccUsage.vanillaMC ? CheckIcon : Xicon}
							title={'Vanilla MC'}
						/>
						<UsageElement title={reccUsage.vanillaPlayerCount} />
						<UsageElement title={reccUsage.vanillaPluginCount} />
						<UsageElement
							icon={reccUsage.lightModpack ? CheckIcon : Xicon}
							title={'Light Modpack'}
						/>
						<UsageElement title={reccUsage.lightPlayerCount} />
						<UsageElement title={reccUsage.lightModCount} />
						<UsageElement
							icon={reccUsage.heavyModpack ? CheckIcon : Xicon}
							title={'Heavy Modpack'}
						/>
						<UsageElement title={reccUsage.heavyPlayerCount} />
						<UsageElement title={reccUsage.heavyModCount} />
					</ReccUsageContainer>
				</ContentContainer>
			</Container>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	padding-top: 3rem;
	display: flex;
	justify-content: center;
	width: 100%;
	padding-bottom: 3.5rem;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2rem 3rem;
	box-shadow: 0 0 0.75rem 0.25rem rgba(0, 0, 0, 0.15);
	border-radius: 0.5rem;
`;

const ContentContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	gap: 3rem;
`;

const ReccUsageContainer = styled.div`
	display: grid;
	align-content: center;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 1.5rem 1rem;
`;

export default RamSelector;
