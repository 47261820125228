import React from 'react';
import styled from 'styled-components';

const SpecCard = ({ title, children }) => {
	return (
		<Wrapper>
			<CardTitle>{title}</CardTitle>
			<ElementContainer>{children}</ElementContainer>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	box-shadow: 0 0 0.75rem 0.25rem rgba(0, 0, 0, 0.15);
	height: max-content;
	margin-top: 3.75rem;
`;

const CardTitle = styled.h3`
	background-color: ${({ theme }) => theme.colors.secondary};
	text-align: center;
	font-size: 1.5rem;
	color: ${({ theme }) => theme.colors.primary};
	padding: 0.75rem 1rem;
	border-radius: 0.5rem 0.5rem 0 0;
`;

const ElementContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.5rem;
	padding: 1.5rem 1rem;
	border-radius: 0 0 0.5rem 0.5rem;
	color: ${({ theme }) => theme.colors.secondary};
`;

export default SpecCard;
