export const theme = {
	colors: {
		background: '#141929',
		primary: '#D6E4EF',
		secondary: '#6C819C',
		tableBorder: 'rgba(108, 129, 156, 0.25)',
		accent: '#366CAA',
		navBorder: 'rgba(54, 108, 170, 0.5)',
		iconRed: '#AD5050',
		iconGreen: '#50AD69',
		iconYellow: '#b8a339'
	},
	fontSizes: {
		xl: '3.25rem',
		lg: '2rem',
		md: '1.5rem',
		base: '1rem'
	}
};
