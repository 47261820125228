import React from 'react';
import styled from 'styled-components';

const CardSection = ({ middleGap, children }) => {
	return (
		<Wrapper>
			<Container middleGap={middleGap}>{children}</Container>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	padding-top: 4rem;
`;

const Container = styled.div`
	display: flex;
	gap: ${({ middleGap }) => middleGap};
	max-width: 75rem;
	width: 95%;
`;

export default CardSection;
