import React from 'react';
import styled from 'styled-components';

const MainFeatures = ({ headerText, children }) => {
	return (
		<Wrapper>
			<HeaderText>{headerText}</HeaderText>
			<FeaturesContainer>{children}</FeaturesContainer>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	background-color: ${({ theme }) => theme.colors.background};
	max-width: 32rem;
	width: 100%;
	padding: 1rem 1rem;
	border-radius: 1rem;
	box-shadow: 0.25rem 0.25rem 1rem 0.125rem rgba(0, 0, 0, 0.25);
`;

const HeaderText = styled.h3`
	color: ${({ theme }) => theme.colors.primary};
	font-size: 1.5rem;
	font-weight: 500;
`;

const FeaturesContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	row-gap: 1rem;
	column-gap: 1rem;
	width: 100%;
`;

export default MainFeatures;
