import React from 'react';
import styled from 'styled-components';

const PlanElement = ({ icon, title }) => {
	return (
		<Wrapper>
			<IconContainer>{icon}</IconContainer>
			<span>{title}</span>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.375rem;
	text-align: center;
	width: 100%;
`;

const IconContainer = styled.div`
	display: flex;
	justify-content: center;
`;

export default PlanElement;
