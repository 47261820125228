import React from 'react';
import styled from 'styled-components';

import { navbarConfig } from '../../config/Placeholders';

const Logo = () => {
	return (
		<Wrapper>
			<ImgContainer>
				<img src={navbarConfig.logo} alt='' />
			</ImgContainer>
			<LogoText>{navbarConfig.companyName}</LogoText>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.5rem;
	width: 100%;
	max-width: 240px;
`;

const ImgContainer = styled.div`
	height: 64px;
	width: 64px;

	& img {
		width: 100%;
	}
`;

const LogoText = styled.div`
	font-size: 1.5rem;
	font-weight: bold;
`;

export default Logo;
