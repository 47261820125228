import React from 'react';
import styled from 'styled-components';

const FeatureItem = ({ featureText }) => {
	return <Wrapper>{featureText}</Wrapper>;
};

const Wrapper = styled.span`
	display: block;
	text-align: center;
	font-size: 1.125rem;
	color: ${({ theme }) => theme.colors.secondary};
`;

export default FeatureItem;
