import React from 'react';
import styled from 'styled-components';

const UsageElement = ({ icon, title, children }) => {
	return (
		<Wrapper>
			<IconContainer>{icon}</IconContainer>
			<span>{title}</span>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 0.375rem;
	font-size: 1rem;
	font-weight: 500;
	color: ${({ theme }) => theme.colors.secondary};
	width: 100%;
`;
const IconContainer = styled.div`
	display: flex;
`;

export default UsageElement;
