import React from 'react';
import styled from 'styled-components';

import { theme } from '../../config/Theme';

const TableRow = ({ title, host1, host2, host3, host4, host5, host6 }) => {
	return (
		<Wrapper>
			<Title>{title}</Title>
			<TextElement>{host1}</TextElement>
			<TextElement>{host2}</TextElement>
			<TextElement>{host3}</TextElement>
			<TextElement>{host4}</TextElement>
			<TextElement>{host5}</TextElement>
			<TextElement>{host6}</TextElement>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1.5fr repeat(6, 1fr);
	width: 100%;
	padding: 1.5rem 0;
`;

const Title = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${theme.colors.primary};
	font-size: 1.125rem;
	height: 100%;
	text-align: center;
`;

const TextElement = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${theme.colors.primary};
	font-size: 1.125rem;
`;

export default TableRow;
