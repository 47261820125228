import Navbar from './components/Navbar/Navbar';
import HeroSection from './components/HeroSection/HeroSection';
import FeaturedPlans from './components/FeaturedPlans/FeaturedPlans';
import CompareTable from './components/CompareTable/CompareTable';
import Locations from './components/Locations/Locations';
import FAQ from './components/FAQ/FAQ';
import Footer from './components/Footer/Footer';

import { ThemeProvider } from 'styled-components';
import { theme } from './config/Theme';

function App() {
	return (
		<ThemeProvider theme={theme}>
			<Navbar />
			<HeroSection />
			<FeaturedPlans />
			<CompareTable />
			<Locations />
			<FAQ />
			<Footer />
		</ThemeProvider>
	);
}

export default App;
