import Logo from '../assets/SkeletonHostlogo.png';

// FEATURE ICON IMPORTS
import {
	FaMicrochip,
	FaHdd,
	FaShieldAlt,
	FaMemory,
	FaServer,
	FaUsers,
	FaInstagram,
	FaTwitter,
	FaDiscord
} from 'react-icons/fa';

import { HiCursorClick, HiChatAlt2 } from 'react-icons/hi';
import { BsArrowRepeat } from 'react-icons/bs';

// COMPARE HOST ICON IMPORTS
import pebbleIcon from '../assets/pebbleIcon.webp';
import mcproIcon from '../assets/mcproIcon.webp';
import biscetIcon from '../assets/bisectIcon.webp';
import apexIcon from '../assets/apexIcon.webp';
import serverproIcon from '../assets/serverproIcon.webp';

export const navbarConfig = {
	logo: Logo,
	companyName: 'SkeletonHost',
	navlinks: [
		{ name: 'Services', link: '/services' },
		{ name: 'Compare', link: '/compare' },
		{ name: 'Locations', link: '/locations' },
		{ name: 'About Us', link: '/about' }
	]
};

export const heroSectionConfig = {
	headerText: 'Quality Hosting on a Budget',
	subHeaderText: 'Plans Starting at just $2.25/month',
	ctaButtonText: 'Get Started',
	featureCardTitle: 'Why Choose Us?',
	features: [
		'One-Click Modpack Installer',
		'24/7 Discord & Ticket Support',
		'Feature',
		'Feature',
		'Feature',
		'Feature',
		'Feature',
		'Feature'
	]
};

export const featuredPlansConfig = {
	toggleButtonText: {
		left: 'Minecraft Hosting',
		right: 'Web Hosting (Coming Soon)'
	},

	leftPlanCard: {
		title: 'Budget',
		features: [
			{ icon: <FaUsers size={48} />, text: 'Unlimited Player Slots' },
			{
				icon: <HiCursorClick size={48} />,
				text: 'One-Click Modpack Installer'
			},
			{ icon: <BsArrowRepeat size={48} />, text: 'Automatic Backups' },
			{ icon: <HiChatAlt2 size={48} />, text: 'Priority Chat Support' }
		],
		pricePerGB: 2.25,
		planIncrement: 'month',
		buttonText: 'Get Started'
	},

	rightPlanCard: {
		title: 'Premium',
		features: [
			{ icon: <FaUsers size={48} />, text: 'Unlimited Player Slots' },
			{
				icon: <HiCursorClick size={48} />,
				text: 'One-Click Modpack Installer'
			},
			{ icon: <BsArrowRepeat size={48} />, text: 'Automatic Backups' },
			{ icon: <HiChatAlt2 size={48} />, text: 'Priority Chat Support' }
		],
		pricePerGB: 4.25,
		planIncrement: 'month',
		buttonText: 'Get Started'
	},

	leftSpecCard: {
		title: 'Plan Specs',
		specs: [
			{ icon: <FaMicrochip size={32} />, text: 'Intel i9-9900k' },
			{ icon: <FaMemory size={32} />, text: 'DDR4 2666MHz Memory' },
			{
				icon: <FaShieldAlt size={32} />,
				text: '480Gbps DDoS Protection'
			},
			{ icon: <FaServer size={32} />, text: 'Dedicated CPU Threads' },
			{ icon: <FaHdd size={32} />, text: 'Unmetered NVMe SSD' }
		]
	},

	rightSpecCard: {
		title: 'Plan Specs',
		specs: [
			{ icon: <FaMicrochip size={32} />, text: 'Intel i9-9900k' },
			{ icon: <FaMemory size={32} />, text: 'DDR4 2666MHz Memory' },
			{
				icon: <FaShieldAlt size={32} />,
				text: '480Gbps DDoS Protection'
			},
			{ icon: <FaServer size={32} />, text: 'Dedicated CPU Threads' },
			{ icon: <FaHdd size={32} />, text: 'Unmetered NVMe SSD' }
		]
	},

	ramSelectorMaxGB: 15
};

export const compareTableConfig = {
	sectionTitle: 'Compare Against Other Hosts',

	compareHeaderRow: {
		title: 'Features Offered',
		icons: [
			Logo,
			pebbleIcon,
			mcproIcon,
			biscetIcon,
			apexIcon,
			serverproIcon
		]
	},
	compareTextRows: [
		{
			title: 'Pricing',
			host1Val: '$2.25/month',
			host2Val: '$2.75/month',
			host3Val: '$4.85/month',
			host4Val: '$3.25/month',
			host5Val: '$2.25/month',
			host6Val: '$5.25/month'
		},
		{
			title: 'Single Thread CPU Score',
			host1Val: '4234',
			host2Val: '3452',
			host3Val: '2348',
			host4Val: '1235',
			host5Val: '6345',
			host6Val: '1239'
		}
	],

	compareIconRows: [
		{
			title: 'Instant Setup',
			host1: true,
			host2: false,
			host3: false,
			host4: true,
			host5: true,
			host6: false
		}
	]
};

export const locationConfig = {
	sectionTitle: 'Available Server Locations',
	locations: [
		{
			locationName: 'Los Angeles, California',
			top: '30%',
			left: '8%'
		},
		{ locationName: 'Dallas, Texas', top: '33%', left: '12.2%' },
		{ locationName: 'Washington, DC', top: '30%', left: '18%' }
	]
};

export const faqConfig = {
	sectionTitle: 'Frequent Questions',
	questions: [
		{
			question: 'How do I allocate more RAM?',
			answer: 'Pay me more money!'
		},
		{
			question: 'How do I allocate more RAM?',
			answer: 'Pay me more money!'
		},
		{
			question: 'How do I allocate more RAM?',
			answer: 'Pay me more money!'
		},
		{
			question: 'How do I allocate more RAM?',
			answer: 'Pay me more money!'
		}
	]
};

export const footerConfig = {
	aboutUs: {
		title: 'About Us',
		text: 'We are pretty cool, not gonna lie. You should buy from us'
	},

	cols: [
		{
			title: 'Social Media',
			rows: [
				{
					icon: <FaInstagram size={18} />,
					text: '@SkeletonHost',
					link: 'https://instagram.com'
				},
				{
					icon: <FaTwitter size={18} />,
					text: '@SkeletonHost',
					link: 'https://twitter.com'
				}
			]
		},
		{
			title: 'Hosting Options',
			rows: [
				{
					icon: null,
					text: 'Minecraft: Java Edition Hosting',
					link: null
				},
				{
					icon: null,
					text: 'Web Hosting (Coming Soon)',
					link: null
				}
			]
		},
		{
			title: 'Control Panels',
			rows: [
				{
					icon: null,
					text: 'Minecraft: Java Edition Panel',
					link: null
				}
			]
		},
		{
			title: 'Server Support',
			rows: [
				{
					icon: <FaDiscord size={18} />,
					text: 'Discord Support Invite',
					link: null
				}
			]
		}
	]
};
