import React from 'react';
import styled from 'styled-components';

import { FaInfoCircle } from 'react-icons/fa';

import { theme } from '../../config/Theme';

const LocationIcon = ({ positions, locationTitle }) => {
	return (
		<Wrapper positions={positions}>
			<FaInfoCircle id='infoIcon' size='18px' />
			<InfoCard id='infoCard'>
				<div id='locationTitle'>{locationTitle}</div>
			</InfoCard>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	position: absolute;
	top: ${({ positions }) => positions[0]};
	left: ${({ positions }) => positions[1]};

	color: ${theme.colors.primary};

	& > #infoIcon {
		box-shadow: 0 0 9px 0 ${theme.colors.accent};
		border-radius: 1rem;
	}

	& > #infoCard {
		visibility: hidden;
		opacity: 0;
		transition: visibility 0.2s, opacity 0.2s linear;
		z-index: 5;
	}

	& > #infoIcon:hover + #infoCard {
		visibility: visible;
		opacity: 1;
	}
`;

const InfoCard = styled.div`
	position: absolute;
	background-color: blue;
	left: 20px;
	top: -20px;
	display: flex;
	flex-direction: column;
	text-align: center;
	background-color: ${theme.colors.background};
	padding: 0.25rem 1.5rem;
	border-radius: 2rem;
	border: 2px solid ${theme.colors.accent};
	font-size: 0.875rem;
	width: max-content;

	& > #locationPing {
		display: flex;
		gap: 0.5rem;
		padding-top: 0.25rem;
		justify-content: center;
	}
`;

export default LocationIcon;
