import React, { useState } from 'react';
import styled from 'styled-components';

const ToggleButton = ({ leftText, rightText }) => {
	const [activeBTN, setActiveBTN] = useState(0);

	return (
		<Wrapper>
			<ButtonContainer>
				<Button
					onClick={() => setActiveBTN(0)}
					isActive={activeBTN === 0 ? true : false}
				>
					{leftText}
				</Button>
				<Button
					onClick={() => setActiveBTN(1)}
					isActive={activeBTN === 1 ? true : false}
				>
					{rightText}
				</Button>
				<ButtonBackground activeButton={activeBTN} />
			</ButtonContainer>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	padding-top: 3.5rem;
`;

const ButtonContainer = styled.div`
	display: flex;
	position: relative;
	border-radius: 2rem;
	width: 38rem;
	box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.15);
`;

const Button = styled.button`
	background: none;
	transition: color 500ms ease-in-out;
	color: ${({ theme, isActive }) =>
		isActive ? theme.colors.primary : theme.colors.secondary};

	border-radius: 2rem;
	width: 50%;
	padding: 1rem 2rem;
	font-size: 1.125rem;
	font-weight: 500;
	z-index: 5;
	cursor: pointer;
	border: none;
`;

const ButtonBackground = styled.div`
	position: absolute;
	transition: transform 500ms ease-out;
	background-color: ${({ theme }) => theme.colors.accent};
	width: 50%;
	height: 100%;
	border-radius: 2rem;
	transform: ${({ activeButton }) =>
		activeButton === 0 ? 'translate(0, 0)' : 'translate(100%, 0)'};
`;

export default ToggleButton;
