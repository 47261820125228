import React from 'react';
import styled from 'styled-components';

const SpecElement = ({ title, icon, altText }) => {
	return (
		<Wrapper>
			<IconContainer>{icon}</IconContainer>
			<span>{title}</span>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.375rem;
	text-align: center;
`;

const IconContainer = styled.div`
	display: flex;
	justify-content: center;
`;

export default SpecElement;
