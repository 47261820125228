import React from 'react';
import styled from 'styled-components';

import SvgSpacer from '../../assets/svgbg.svg';
import AboutSection from './AboutSection';
import FooterColumn from './FooterColumn';
import { footerConfig } from '../../config/Placeholders';

const Footer = () => {
	return (
		<Wrapper>
			<SvgContainer>
				<img src={SvgSpacer} alt='' />
			</SvgContainer>
			<ContentContainer>
				<AboutSection />
				{footerConfig.cols.map((col, i) => (
					<FooterColumn title={col.title} rows={col.rows} key={i} />
				))}
			</ContentContainer>
		</Wrapper>
	);
};

const Wrapper = styled.section`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 3rem;
`;

const ContentContainer = styled.div`
	width: 90%;
	max-width: 75rem;
	display: grid;
	grid-template-columns: repeat(${footerConfig.cols.length + 1}, 1fr);
	column-gap: 1rem;
`;

const SvgContainer = styled.div`
	display: flex;
	justify-content: center;
	min-height: 8.875rem;
	width: 100%;

	& img {
		height: 100%;
		rotate: 180deg;
	}
`;

export default Footer;
