import React from 'react';
import styled from 'styled-components';

import { FaAngleRight } from 'react-icons/fa';
import { theme } from '../../config/Theme';

const FaqItem = ({ question, answer, isActive, setActiveState, itemID }) => {
	return (
		<Wrapper
			isActive={isActive}
			onClick={() =>
				isActive ? setActiveState(null) : setActiveState(itemID)
			}
		>
			<QuestionContainer isActive={isActive}>
				<span>{question}</span>
				<IconContainer isActive={isActive}>
					<FaAngleRight size={'18px'} />
				</IconContainer>
			</QuestionContainer>

			<AnswerBox isActive={isActive}>{answer}</AnswerBox>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;

	gap: 0.5rem;
	color: ${({ isActive }) =>
		isActive ? theme.colors.primary : theme.colors.secondary};

	& > span {
		font-size: 1.125rem;
	}
	cursor: pointer;
	transition: all 0.5s ease-in-out;
`;

const IconContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	rotate: ${({ isActive }) => (isActive ? '90deg' : '0deg')};
	transition: rotate 0.5s ease-in-out;
`;

const QuestionContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 0.5rem 0;
	border-bottom: 1px solid;
	border-color: ${({ isActive }) =>
		isActive ? theme.colors.primary : theme.colors.secondary};
	user-select: none;
`;

const AnswerBox = styled.div`
	max-height: ${({ isActive }) => (isActive ? '150px' : '0px')};
	opacity: ${({ isActive }) => (isActive ? 1 : 0)};
	overflow-y: hidden;
	transition: all 0.75s ease-in-out;
`;

export default FaqItem;
