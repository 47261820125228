import React from 'react';
import styled from 'styled-components';

import TableTextRow from './TableTextRow';
import TableHeaderRow from './TableHeaderRow';
import TableIconRow from './TableIconRow';

import { theme } from '../../config/Theme';
import { compareTableConfig } from '../../config/Placeholders';

const CompareTable = () => {
	return (
		<Wrapper>
			<Title>{compareTableConfig.sectionTitle}</Title>
			<Container>
				<Table>
					<TableHeaderRow
						title={compareTableConfig.compareHeaderRow.title}
						icons={compareTableConfig.compareHeaderRow.icons}
					/>
					{compareTableConfig.compareTextRows.map((row, i) => (
						<TableTextRow
							title={row.title}
							host1={row.host1Val}
							host2={row.host2Val}
							host3={row.host3Val}
							host4={row.host4Val}
							host5={row.host5Val}
							host6={row.host6Val}
							key={i}
						/>
					))}

					{compareTableConfig.compareIconRows.map((iconRow, i) => (
						<TableIconRow
							title={iconRow.title}
							host1={iconRow.host1}
							host2={iconRow.host2}
							host3={iconRow.host3}
							host4={iconRow.host4}
							host5={iconRow.host5}
							host6={iconRow.host6}
							key={i}
						/>
					))}
				</Table>
			</Container>
		</Wrapper>
	);
};

const Wrapper = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding-top: 4rem;
	padding-bottom: 5rem;
	gap: 2rem;
`;

const Title = styled.h2`
	font-size: 2.25rem;
	font-weight: 500;
	color: ${theme.colors.accent};
`;

const Container = styled.div`
	width: 95%;
	max-width: 75rem;
	box-shadow: 0 0 0.75rem 0.25rem rgba(0, 0, 0, 0.1);
	border-radius: 1rem;
`;

const Table = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;

	& > div {
		border-bottom: solid 1px ${theme.colors.tableBorder};
	}

	& > div:last-of-type {
		border: none;
	}
`;

export default CompareTable;
