import React, { useState } from 'react';
import styled from 'styled-components';

import { theme } from '../../config/Theme';
import { faqConfig } from '../../config/Placeholders';

import FaqItem from './FaqItem';

const FAQ = () => {
	const [activeQuestion, setActiveQuestion] = useState(null);

	return (
		<Wrapper>
			<Title>{faqConfig.sectionTitle}</Title>
			<QuestionsContainer>
				{faqConfig.questions.map((item, i) => (
					<FaqItem
						question={item.question}
						answer={item.answer}
						setActiveState={setActiveQuestion}
						isActive={activeQuestion === i}
						itemID={i}
						key={i}
					/>
				))}
			</QuestionsContainer>
		</Wrapper>
	);
};

const Wrapper = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;
	width: 100%;
	padding: 5rem 0;
`;

const Title = styled.h2`
	font-size: 2.25rem;
	font-weight: 500;
	color: ${theme.colors.accent};
`;

const QuestionsContainer = styled.div`
	width: 90%;
	max-width: 75rem;
	box-shadow: 0 0 0.75rem 0.25rem rgba(0, 0, 0, 0.1);
	padding: 1rem;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 4rem;
	row-gap: 2rem;
`;

export default FAQ;
