import React from 'react';
import styled from 'styled-components';

import { theme } from '../../config/Theme';

import WorldMap from '../../assets/worldMap.svg';

import LocationIcon from './LocationIcon';
import { locationConfig } from '../../config/Placeholders';

const Locations = () => {
	return (
		<Wrapper>
			<Title>{locationConfig.sectionTitle}</Title>
			<MapContainer>
				<img src={WorldMap} alt='' />
				{locationConfig.locations.map((location, i) => (
					<LocationIcon
						positions={[location.top, location.left]}
						locationTitle={location.locationName}
						key={i}
					/>
				))}
			</MapContainer>
		</Wrapper>
	);
};

const Wrapper = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;
	width: 100%;
	padding: 5rem 0;
`;

const MapContainer = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	width: 95%;
	max-width: 75rem;
	padding: 1rem 0;
	box-shadow: 0 0 0.75rem 0.25rem rgba(0, 0, 0, 0.1);
	border-radius: 1rem;

	& > img {
		width: 95%;
	}
`;

const Title = styled.h2`
	font-size: 2.25rem;
	font-weight: 500;
	color: ${theme.colors.accent};
`;

export default Locations;
