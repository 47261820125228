import React from 'react';
import styled from 'styled-components';

import BgImage from '../../assets/SkeletonHostHeaderImage.png';
import CTA from './CTA';
import MainFeatures from './MainFeatures';
import FeatureItem from './FeatureItem';

import { heroSectionConfig } from '../../config/Placeholders';

const HeroSection = () => {
	return (
		<Wrapper>
			<Container>
				<CTA
					headerText={heroSectionConfig.headerText}
					subText={heroSectionConfig.subHeaderText}
					buttonText={heroSectionConfig.ctaButtonText}
				/>
				<MainFeatures headerText={heroSectionConfig.featureCardTitle}>
					{heroSectionConfig.features.map((item, i) => (
						<FeatureItem featureText={item} key={i} />
					))}
				</MainFeatures>
			</Container>
		</Wrapper>
	);
};

const Wrapper = styled.section`
	display: flex;
	justify-content: center;
	background-image: url(${BgImage});
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	margin: 0 auto;
`;

const Container = styled.div`
	max-width: 75rem;
	width: 95%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 7.5rem 2rem;
`;

export default HeroSection;
