import React from 'react';
import styled from 'styled-components';

const PlanCard = ({
	title,
	buttonText,
	primary,
	planPrice,
	planIncrement,
	planCurrency,
	children
}) => {
	return (
		<Wrapper>
			<CardTitle primary={primary}>{title}</CardTitle>
			<ElementContainer>{children}</ElementContainer>
			<PlanPrice>
				<PlanPriceTextSmall>{planCurrency}</PlanPriceTextSmall>
				{planPrice}
				<PlanPriceTextSmall>/{planIncrement}</PlanPriceTextSmall>
			</PlanPrice>
			<CardButton primary={primary}>{buttonText}</CardButton>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding-bottom: 1.75rem;
	align-items: center;
	box-shadow: 0 0 0.75rem 0.25rem rgba(0, 0, 0, 0.15);
	width: 60%;
`;

const CardTitle = styled.h2`
	background-color: ${({ primary, theme }) =>
		primary ? theme.colors.accent : theme.colors.secondary};
	color: ${({ theme }) => theme.colors.primary};
	font-size: 2.25rem;
	width: 100%;
	text-align: center;
	padding: 0.75rem 0;
	border-radius: 0.25rem 0.25rem 0 0;
`;

const ElementContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
	align-items: center;
	color: ${({ theme }) => theme.colors.primary};
	padding: 1.5rem 1rem;
`;

const PlanPriceTextSmall = styled.span`
	font-size: 1.5rem;
`;

const PlanPrice = styled.span`
	text-align: center;
	font-size: 2rem;
	color: ${({ theme }) => theme.colors.primary};
	padding-top: 0.5rem;
	padding-bottom: 1.75rem;
`;

const CardButton = styled.button`
	padding: 0.75rem 1.5rem;
	background-color: ${({ primary, theme }) =>
		primary ? theme.colors.accent : theme.colors.secondary};
	color: ${({ theme }) => theme.colors.primary};
	font-size: 1.5rem;
	font-weight: 500;
	border-radius: 0.5rem;
	cursor: pointer;
	transition-duration: 200ms;
	&:hover {
		background-color: ${({ theme }) => theme.colors.primary};
		color: ${({ primary, theme }) =>
			primary ? theme.colors.accent : theme.colors.secondary};
	}
	border: none;
`;

export default PlanCard;
