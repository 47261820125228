import React from 'react';
import styled from 'styled-components';

const CTA = ({ headerText, subText, buttonText }) => {
	return (
		<Wrapper>
			<TextContainer>
				<HeaderText>{headerText}</HeaderText>
				<SubText>{subText}</SubText>
			</TextContainer>
			<Button>
				<a href='https://google.com'>{buttonText}</a>
			</Button>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 26.5rem;
	gap: 1.5rem;
`;

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

const HeaderText = styled.h1`
	font-size: 3.25rem;
	font-weight: bold;
	line-height: 100%;
	margin: 0;
	color: ${({ theme }) => theme.colors.primary};
`;

const SubText = styled.p`
	font-size: 1.125rem;
	font-weight: 500;
	color: ${({ theme }) => theme.colors.primary};
`;

const Button = styled.button`
	font-size: 1.5rem;
	font-weight: 500;
	border-radius: 0.5rem;
	width: 50%;
	padding: 0.75rem 1.5rem;
	background-color: ${({ theme }) => theme.colors.accent};
	transition-duration: 200ms;
	cursor: pointer;
	border: none;

	&:hover {
		background-color: ${({ theme }) => theme.colors.secondary};
	}

	& a,
	a:visited,
	a:active {
		color: ${({ theme }) => theme.colors.primary};
		text-decoration: none;
	}
`;

export default CTA;
