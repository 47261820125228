import React, { useState } from 'react';
import styled from 'styled-components';

import SvgSpacer from '../../assets/svgbg.svg';
import ToggleButton from './ToggleButton';
import CardSection from './CardSection';
import SpecCard from './SpecCard/SpecCard';
import SpecElement from './SpecCard/SpecElement';
import PlanCard from './PlanCard/PlanCard';
import PlanElement from './PlanCard/PlanElement';
import RamSelector from './RamSelectorReccUsage/RamSelector';

import { featuredPlansConfig } from '../../config/Placeholders';

const FeaturedPlans = () => {
	const [ramVal, setRamVal] = useState(1);

	return (
		<Wrapper>
			<SvgContainer>
				<img src={SvgSpacer} alt='' />
			</SvgContainer>
			<ToggleButton
				leftText={featuredPlansConfig.toggleButtonText.left}
				rightText={featuredPlansConfig.toggleButtonText.right}
			/>
			<CardSection middleGap={'5rem'}>
				<LeftSection>
					<SpecCard title={'Plan Specs'}>
						{featuredPlansConfig.leftSpecCard.specs.map(
							(leftItem, i) => (
								<SpecElement
									title={leftItem.text}
									icon={leftItem.icon}
									key={i}
								/>
							)
						)}
					</SpecCard>
					<PlanCard
						title={featuredPlansConfig.leftPlanCard.title}
						buttonText={featuredPlansConfig.leftPlanCard.buttonText}
						planPrice={`${(
							ramVal * featuredPlansConfig.leftPlanCard.pricePerGB
						).toFixed(2)}`}
						planIncrement={
							featuredPlansConfig.leftPlanCard.planIncrement
						}
						planCurrency={'$'}
					>
						{featuredPlansConfig.leftPlanCard.features.map(
							(leftItem, i) => (
								<PlanElement
									icon={leftItem.icon}
									title={leftItem.text}
									key={i}
								/>
							)
						)}
					</PlanCard>
				</LeftSection>
				<RightSection>
					<PlanCard
						title={featuredPlansConfig.rightPlanCard.title}
						buttonText={
							featuredPlansConfig.rightPlanCard.buttonText
						}
						planPrice={`${(
							ramVal *
							featuredPlansConfig.rightPlanCard.pricePerGB
						).toFixed(2)}`}
						planIncrement={
							featuredPlansConfig.rightPlanCard.planIncrement
						}
						planCurrency={'$'}
						primary
					>
						{featuredPlansConfig.rightPlanCard.features.map(
							(rightItem, i) => (
								<PlanElement
									icon={rightItem.icon}
									title={rightItem.text}
									key={i}
								/>
							)
						)}
					</PlanCard>
					<SpecCard title={'Plan Specs'}>
						{featuredPlansConfig.leftSpecCard.specs.map(
							(rightItem, i) => (
								<SpecElement
									title={rightItem.text}
									icon={rightItem.icon}
									key={i}
								/>
							)
						)}
					</SpecCard>
				</RightSection>
			</CardSection>
			<RamSelector
				maxRam={featuredPlansConfig.ramSelectorMaxGB}
				ramValState={setRamVal}
			/>
			<SvgContainer>
				<img style={{ rotate: '180deg' }} src={SvgSpacer} alt='' />
			</SvgContainer>
		</Wrapper>
	);
};

const Wrapper = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

const SvgContainer = styled.div`
	display: flex;
	justify-content: center;
	min-height: 8.875rem;
	width: 100%;

	& img {
		height: 100%;
	}
`;

const LeftSection = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 50%;
	gap: 2rem;
`;

const RightSection = styled.div`
	display: flex;
	width: 50%;
	gap: 2rem;
`;

export default FeaturedPlans;
