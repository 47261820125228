import React from 'react';
import styled from 'styled-components';

const NavLink = ({ title, link }) => {
	return <Wrapper href={link}>{title}</Wrapper>;
};

const Wrapper = styled.a`
	display: flex;
	align-items: center;
	height: 100%;
	cursor: pointer;
	transition-duration: 200ms;

	&,
	&:hover,
	&:focus,
	&:active {
		color: inherit;
		text-decoration: none;
	}

	&:hover {
		box-sizing: initial;
		box-shadow: 0px 4px ${({ theme }) => theme.colors.accent};
	}
`;

export default NavLink;
